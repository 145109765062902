import React from "react"
import styled from "styled-components"
import { colors, fontDecor, fontBody } from "../utils/styles"
import { FaArrowRight, FaTimes } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { nowIsBetweenContentfulDates } from "../utils/dates"

/**
 * Begin Styled Components
 */

const ComponentWrapper = styled.div`
  dialog {
    background: transparent;
    border: 0px solid ${colors.mainWhite};
    border-radius: 0.5rem;
    padding: 0;
    box-shadow: oklch(0 0 0 / 0.25) 0px 5px 10px 2px,
      oklch(0 0 0 / 0.05) 0px 10px 15px 5px;

    &::backdrop {
      backdrop-filter: blur(0.25rem);
      background: oklch(1 0 0 / 0.25);
    }
  }
`

const Wrapper = styled.section`
  background: ${colors.primaryColor};
  border-radius: 0.25rem;
  isolation: isolate;
  max-width: 90vw;
  overflow: clip;
  position: relative;
  width: 400px;

  &::after {
    border-radius: 0.65rem;
    position: absolute;
    content: "";
    inset: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    outline: 1px solid ${colors.secondaryColor};
    outline-offset: -0.5rem;
    z-index: 9;
  }
`

const CloseBtn = styled.button`
  --bg: ${colors.secondaryColor};
  --color: ${colors.mainBlack};

  background: var(--bg);
  border: 0;
  color: var(--color);
  cursor: pointer;
  display: grid;
  font-size: 1.5rem;
  height: 2rem;
  place-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  z-index: 10;

  &:focus-visible {
    outline: 0;
  }
`

const ImageWrapper = styled.div`
  height: 250px;
  overflow: hidden;
  position: relative;
`

const Article = styled.article`
  color: ${colors.mainWhite};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 2rem;
`

const ArticleHeader = styled.header`
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  gap: 0.4rem;
`

const HeadingIntro = styled.span`
  font: ${fontBody({ size: `0.85rem` })};
  letter-spacing: 1px;
  text-transform: uppercase;
`

const Heading = styled.h2`
  color: ${colors.secondaryColor};
  font: ${fontDecor({ size: `1.85rem`, weight: 700 })};
`

const ArticleBody = styled.div`
  font: ${fontBody({ lineHeight: 1.1 })};
  
  p {
    margin: 0;
  }
`

const ArticleFooter = styled.footer`
  display: flex;
  justify-content: end;
  padding: 1rem 0 0.5rem;
`

const MenuBtn = styled.a`
    align-items: center;
    background: ${colors.mainBlack};
    color: ${colors.secondaryColor};
    display: flex;
    gap: 0.35rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;

    svg {
      transition: translate 0.2s;
    }

    &:hover {
      color: ${colors.mainWhite};

      svg {
        translate: 0.15rem 0;
      }
    }
`

/**
 * End Styled Components
 */


const SpecialOccasionModal = () => {
  const { menu } = useStaticQuery(graphql`
    query {
      menu: contentfulMenu(
        restaurant: { elemMatch: { name: { eq: "Azalea Bar & Kitchen" } } }
        specialOccasionMenu: { eq: true }
      ) {
        coverImage {
          gatsbyImageData(
            quality: 75,
            formats: WEBP,
            placeholder: BLURRED,
            width: 400
          )
        }
        name
        pdf {
          file {
            url
          }
        }
        restaurantSpecialOccasion {
          description {
            raw
          }
          name
          showFrom
          showUntil
        }
      }
    }
  `)

  const modalRef = React.useRef(null)

  React.useEffect(() => {    
    const modal = sessionStorage?.getItem(`specialsModal`)
    if (modal && modal === `1`) return 
    openModal()
  }, [])

  const closeModal = () => modalRef.current?.close()

  const openModal = () => {
    modalRef.current?.showModal()
    if (process.env.NODE_ENV === `development`) return
    sessionStorage?.setItem(`specialsModal`, `1`)
  }

  if (!menu) return <></>

  const { showFrom, showUntil } = menu.restaurantSpecialOccasion
  if (!nowIsBetweenContentfulDates(showFrom, showUntil)) return <></>

  const descriptionHtml = documentToHtmlString(
    JSON.parse(menu.restaurantSpecialOccasion.description.raw)
  )
  
  const handleClick = ({ target }) => {
    if (target.tagName !== `DIALOG`) return
    closeModal()
  }

  return (
    <ComponentWrapper onClick={handleClick}>
      <dialog ref={modalRef}>
        <Wrapper>
          <CloseBtn onClick={closeModal}><FaTimes /></CloseBtn>
          {menu.coverImage ? (
            <ImageWrapper>
              <GatsbyImage
                alt={`${menu.restaurantSpecialOccasion.name}`}
                image={getImage(menu.coverImage)}
                style={{ height: `100%` }}
              />
            </ImageWrapper>
          ) : <div style={{ paddingTop: `1rem` }} />}
          <Article>
            <ArticleHeader>
              <Heading>{menu.specialOccasion}</Heading>
              <HeadingIntro>Join us for</HeadingIntro>
            </ArticleHeader>
            {descriptionHtml && (
              <ArticleBody dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            )}
            <ArticleFooter>
              <MenuBtn href={menu.pdf.file.url}>View the Menu <FaArrowRight /></MenuBtn>
            </ArticleFooter>
          </Article>
        </Wrapper>
      </dialog>
    </ComponentWrapper>
  )
}

export default SpecialOccasionModal